

























































import { Component, Vue } from "vue-property-decorator";
import { Contract } from "@/models/contract";
import { formatDate } from "@/lib/date";
import XCardWithList from "../SimpleCardWithList.vue";
import ContractIcon from "../ContractIcon.vue";
import { mapState } from "vuex";

type ListItem = {
  key: string;
  value: string;
  mdi?: string;
};

@Component({
  components: { XCardWithList, ContractIcon },
  computed: {
    ...mapState({ currentContract: "contract" }),
  },
  filters: {
    formatDate(value: string) {
      // Если value - это &mdash; (html-мнемоника), то вернуть value,
      // иначе вызвать ф-ю formatDate.
      return value.charCodeAt(0) === 8212 ? value : formatDate(value, "full");
    },
  },
})
class ParamTabContent extends Vue {
  [x: string]: any;

  get contract(): Contract | null {
    return this.currentContract.contract;
  }

  get commonContractInfo() {
    const info = { type: "", title: "", subtitle: "" };

    if (this.contract) {
      info.type = this.contract["видпотребления"];
      info.title = `Договор № ${this.contract["номер"]}`;
      info.subtitle = this.contract["тема"];
    }

    return info;
  }

  get contractDetails(): ListItem[] {
    const list = [
      { key: "Тип", value: "" },
      { key: "Грузополучатель", value: "" },
      { key: "Категория", value: "" },
      { key: "Отрасль", value: "" },
    ];
    const requiredKeys = ["типдоговора", "название", "категория", "отрасль"];

    return this.prepareList(list, requiredKeys);
  }

  get contractTerms(): ListItem[] {
    const list = [
      { key: "начало", value: "" },
      { key: "окончание", value: "" },
      { key: "подписание", value: "" },
      { key: "расторжение", value: "" },
    ];
    const requiredKeys = [
      "начало договора",
      "окончание",
      "дата подписания",
      "дата расторжения",
    ];

    return this.prepareList(list, requiredKeys);
  }

  get contractContacts(): ListItem[] {
    const list = [
      { key: "Телефон", value: "", mdi: "mdi-phone" },
      { key: "Адрес", value: "", mdi: "mdi-map-marker" },
      { key: "Адрес доставки", value: "", mdi: "mdi-map-marker-radius" },
    ];
    const requiredKeys = ["", "адрес", "адрес доставки"];

    return this.prepareList(list, requiredKeys);
  }

  private prepareList(list: ListItem[], requiredKeys: string[]): ListItem[] {
    if (this.contract) {
      return requiredKeys.reduce((acc, key, i) => {
        if (this.contract) {
          acc[i].value = key && this.contract[key];
        }

        return acc;
      }, list);
    }

    return list;
  }
}

export default ParamTabContent;
